<template>
  <div>
    <page-title :title="campaign.description" :breadcrumbs-items="breadcrumbsItems"></page-title>
    <v-row>
      <v-col cols="12" md="3">
        <v-card class="fill-height" color="transparent" elevation="0">
          <div class="campaign-detail-sticky">
            <CampaignDetailCampaign class="mb-2" ref="refCampaignDetail"></CampaignDetailCampaign>
            <CampaignAttachments class="mb-2" ref="refCampaignAttachments"></CampaignAttachments>
            <ContactPerson class="mb-2" ref="refContactPerson"></ContactPerson>
            <v-btn class="success" block large @click="redirect('ChatUs')">Chat With Us</v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="9">
        <v-row>
          <v-col>
            <CampaignImages class="mb-2" ref="refCampaignImages"></CampaignImages>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <Order class="mb-2" ref="refOrder"></Order>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <Claim class="mb-2" ref="refClaim"></Claim>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <InvoiceUnpaidTable ref="refInvoiceUnpaid" :campaign_no_="no_"></InvoiceUnpaidTable>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CampaignDetailCampaign from './components/Campaign.vue'
import CampaignAttachments from './components/CampaignAttachments.vue'
import CampaignImages from './components/CampaignImages.vue'
import ContactPerson from './components/ContactPerson.vue'
import InvoiceUnpaidTable from './components/InvoiceUnpaidTable.vue'

import Order from './components/Order.vue'
import Claim from './components/Claim.vue'

export default {
  components: {
    CampaignDetailCampaign,
    CampaignAttachments,
    CampaignImages,
    ContactPerson,
    InvoiceUnpaidTable,
    Order,
    Claim,
  },

  data: () => ({
    title: 'Campaign Detail',
    breadcrumbsItems: [
      {
        text: 'Dashboard',
        disabled: false,
        href: '#',
      },
      {
        text: 'Campaign',
        disabled: false,
        href: '/campaign',
      },
      {
        text: 'Detail',
        disabled: true,
        href: '#',
      },
    ],
    sheet: true,
    loading1: null,
    no_: null,
    campaign: {},
    summary: {
      Claim: {},
      Order: {},
    },
    latesInvoice: [],
    images: [],
    attachments: [],
  }),
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    async getDetail() {
      this.campaign = {}
      await this.$axios
        .get('/campaign/detail', {
          params: { no_: this.no_ },
          cacheConfig: true,
        })
        .then(res => {
          res = res.data
          if (res.status == 'success') {
            this.campaign = res.data.campaign
          }
        })

      this.$refs.refCampaignDetail.getData(this.no_)
      this.$refs.refCampaignAttachments.getData(this.no_)
      this.$refs.refCampaignImages.getData(this.no_)
      this.$refs.refOrder.getData(this.no_)
      this.$refs.refClaim.getData(this.no_)
      this.$refs.refInvoiceUnpaid.getData(this.no_)
      this.$refs.refContactPerson.getData(this.no_)
    },
  },
  beforeCreate() {},
  beforeMount() {
    // this.$store.state.showToolbar = false;

    this.no_ = this.$route.params.no_
    this.$store.state.pageInfo = {
      title: this.title,
    }
    this.requiredLogin()
  },
  mounted() {
    // this.$store.state.showToolbar = false;

    this.dialog = true

    this.getDetail()

    this.setTitle('Campaign Detail')

    this.$store.state.showBackButton = true
  },
}
</script>

<style>
.campaign-detail-sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 78px;
}
</style>