<template><v-dialog
    v-model="dialog"
    max-width="400"
  >
    <v-card v-if="(typeof dataDetail.contact !== 'undefined')">
      <v-img
        :src="require('@/assets/images/bg-contact.png')"
        height="250"
        gradient="to top right, rgba(191, 191, 191, 0.33), rgba(176, 170, 170, 0.7)"
      >
        <div class="d-flex align-stretch justify-center fill-height">
          <div class="d-flex align-center justify-center flex-column py-3 fill-height">
            <v-avatar
              size="80"
              class="mb-2"
            >
              <v-img
                :src="dataDetail.contact.picture"
                height="80"
              ></v-img>
            </v-avatar>

            <v-card-title class="text-center my-0 py-0">
              {{ dataDetail.contact.first_name + ' ' + dataDetail.contact.last_name }}
            </v-card-title>
            <v-card-text class="text-center my-0 py-0">
              {{ dataDetail.contact.job_title }} <span v-if="dataDetail.contact.job_title!=''">in</span> {{ dataDetail.contact.company_name }}
            </v-card-text>
          </div>
        </div>
      </v-img>
      <v-card-title class="">
        Account Details
      </v-card-title>
      <v-list-item v-if="dataDetail.contact.first_name!=''">
        <v-list-item-icon>
          <v-icon>{{ icons.mdiAccountOutline }}</v-icon>
        </v-list-item-icon>
        <v-list-item-subtitle class="text-left">{{ dataDetail.contact.first_name + ' ' + dataDetail.contact.last_name }}</v-list-item-subtitle>
      </v-list-item>
      <v-list-item v-if="dataDetail.contact.email!=''">
        <v-list-item-icon>
          <v-icon>{{ icons.mdiEmail }}</v-icon>
        </v-list-item-icon>
        <v-list-item-subtitle class="text-left">{{ dataDetail.contact.email }}</v-list-item-subtitle>
      </v-list-item>
      <v-list-item v-if="dataDetail.contact.type_!=''">
        <v-list-item-icon>
          <v-icon>{{ icons.mdiTag }}</v-icon>
        </v-list-item-icon>
        <v-list-item-subtitle class="text-left">{{ dataDetail.contact.type_ }}</v-list-item-subtitle>
      </v-list-item>
      <v-list-item v-if="dataDetail.contact.job_title!=''">
        <v-list-item-icon>
          <v-icon>{{ icons.mdiBagSuitcase }}</v-icon>
        </v-list-item-icon>
        <v-list-item-subtitle class="text-left">{{ dataDetail.contact.job_title }}</v-list-item-subtitle>
      </v-list-item>
      <v-list-item v-if="dataDetail.contact.company_name!=''">
        <v-list-item-icon>
          <v-icon>{{ icons.mdiOfficeBuilding }}</v-icon>
        </v-list-item-icon>
        <v-list-item-subtitle class="text-left">{{ dataDetail.contact.company_name }}</v-list-item-subtitle>
      </v-list-item>
      <v-list-item v-if="dataDetail.contact.office_no!=''">
        <v-list-item-icon>
          <v-icon>{{ icons.mdiPhone }}</v-icon>
        </v-list-item-icon>
        <v-list-item-subtitle class="text-left">{{ dataDetail.contact.office_no }}</v-list-item-subtitle>
      </v-list-item>

      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="action"></slot>
        <v-btn
          @click.stop="dialog = !dialog"
          text
        >Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mdiChevronUp,
  mdiChevronDown,
  mdiCartPlus,
  mdiShareVariantOutline,
  mdiLockOpenOutline,
  mdiStarOutline,
  mdiAccountOutline,
  mdiTrendingUp,
  mdiHelpCircleOutline,
  mdiOfficeBuilding,
  mdiPhone,
  mdiBagSuitcase,
  mdiEmail,
  mdiTag,
} from '@mdi/js'

export default {
  data() {
    return {
      dialog: false,
      isCardDetailsVisible: false,
      dataDetail: {},
      showDetail: false,
      icons: {
        mdiChevronUp,
        mdiChevronDown,
        mdiCartPlus,
        mdiShareVariantOutline,
        mdiLockOpenOutline,
        mdiStarOutline,
        mdiAccountOutline,
        mdiTrendingUp,
        mdiHelpCircleOutline,
        mdiOfficeBuilding,
        mdiPhone,
        mdiBagSuitcase,
        mdiEmail,
        mdiTag,
      },
    }
  },

  methods: {
    show() {
      this.dialog = true
    },
    hide() {
      this.dialog = false
    },

    getDetail(id) {
      this.dialog = false

      this.$store.state.showPageOverlay = true
      var params = {
        id: id,
      }
      this.$axios
        .get('contact/detail', {
          params: params,
          cacheConfig: true,
        })
        .then(res => {
          this.$store.state.showPageOverlay = false
          if (res.status) {
            var resData = res.data
            if (resData.status == 'success') {
              this.dataDetail = resData.data
            }
          } else {
            this.showAlert(resData.message)
          }

          this.dialog = true
        })
        .catch(error => {
          this.$store.state.showPageOverlay = false
          if (error.response) {
            this.$awn.alert('Internal Server Error')
            // console.log(error.response.headers)
          } else {
            // console.log('Error', error.message)
          }
        })
    },
  },
}
</script>

<style scoped>
.bbg {
  background-color: #333;
  background-image: linear-gradient(135deg, #393939 0%, #555 100%);
}
</style>
