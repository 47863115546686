<template>
  <div>
    <v-container style="min-height: 50px">
      <v-row class="fill-height" align-content="center" justify="center">
        <v-col cols="12" class="text-subtitle-1 text-center">
          <div class=""><v-progress-circular :size="18" color="primary" indeterminate></v-progress-circular> Loading... </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'LoadingComponent',
}
</script>