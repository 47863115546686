<template>
  <div>
    <v-card class="greeting-card">
      <v-img src="@/assets/images/no-image.png" v-if="campaign.img_src == ''" height="250" class="white--text align-end" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)">
        <div class="text-h5 mx-2">{{ ucFirstAll(campaign.description) }}</div>
      </v-img>
      <v-img v-if="campaign.img_src != ''" :src="campaign.img_src" height="200" class="white--text align-end" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
          </v-row>
        </template>
        <div class="text-h5 mx-2">{{ ucFirstAll(campaign.description) }}</div>
      </v-img>
      <v-divider class="my-2"></v-divider>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>No.:</v-list-item-title>
          <v-list-item-subtitle>{{ campaign.no_ }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>Decription:</v-list-item-title>
          <v-list-item-subtitle>{{ campaign.description }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </div>
</template>


<script>
export default {
  data: () => ({
    no_: null,
    campaign: {},
  }),
  methods: {
    async getData(no_) {
      this.campaign = {}
      this.no_ = no_
      await this.$axios
        .get('/campaign/detail', {
          params: { no_: no_ },
          cacheConfig: true,
        })
        .then(res => {
          var resData = res.data

          if (resData.status == 'success') {
            this.campaign = resData.data.campaign
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.greeting-card {
  position: relative;
  .greeting-card-bg {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .greeting-card-trophy {
    position: absolute;
    bottom: 10%;
    right: 8%;
  }
}
// rtl
.v-application {
  &.v-application--is-rtl {
    .greeting-card-bg {
      right: initial;
      left: 0;
      transform: rotateY(180deg);
    }
    .greeting-card-trophy {
      left: 8%;
      right: initial;
    }
  }
}
</style>
