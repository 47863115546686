<template>
  <div v-if="imageLoad >= 1">
    <v-sheet class="mx-auto" elevation="0" outlined color="transparent">
      <v-card color="transparent" elevation="0">
        <v-img :src="selectedImage" height="400">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-card>

      <v-sheet class="mx-auto" elevation="0" color="transparent">
        <v-slide-group v-model="slideGroup" active-class="success" :show-arrows="images.length > 2">
          <v-slide-item v-for="(slide, i) in images" :key="i" v-slot="{ active, toggle }">
            <v-card :color="active ? undefined : 'grey lighten-1'" class="ma-4" width="100" @click="toggle; selectedImage=slide.img_src">
              <v-img :src="slide.img_src" height="60">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-scale-transition>
                      <v-icon v-if="active" color="white" size="30" v-text="'mdi-close-circle-outline'"></v-icon>
                    </v-scale-transition>
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
    </v-sheet>
  </div>
</template>
<script>
export default {
  data: () => ({
    no_: null,
    images: [],
    imageLoad: 1,
    hideSlider: false,
    slideGroup: null,
    selectedImage:null,
  }),
  methods: {
    async getData(no_) {
      this.hideSlider = false
      this.images = []
      this.imageLoad = 1
      this.no_ = no_
      await this.$axios
        .get('/campaign/images', {
          params: { no_: no_ },
          // cacheConfig: true,
        })
        .then(res => {
          var resData = res.data
          if (resData.status == 'success') {
            this.images = resData.data.images

            if (this.images.length == 0) {
              this.hideSlider = false
              this.imageLoad = 0
            }else{
              this.imageLoad = 2

              var firstImage = this.images[0];

              this.selectedImage = firstImage.img_src
            }
          }
        })
    },
  },
}
</script>

<style>
.dimming1 {
  background-color: #00000052 !important;
  color: #fff;
  padding-left: 30px;
  padding-right: 30px;
}
</style>