<template>
  <v-card outlined elevation="2">
    <v-card-title>
      {{ title }}
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" fab icon class="mt-1" color="primary" @click="getData()">
            <v-icon>mdi-sync</v-icon>
          </v-btn>
        </template>
        <span>Refresh</span>
      </v-tooltip>
    </v-card-title>
    <loading-component v-if="showLoadingComponent"></loading-component>

    <v-data-table v-if="hideTable == false" dense :loading="loading" :loading-text="loadingText" :no-data-text="'Great!!!, no invoice unpaid..'" :headers="tableHeaders" :items="invoices" item-key="name" class="elevation-1">
      <template v-slot:[`item.invoice_no_`]="{ item }">
        <a href="javascript:;" @click="downloadPdf(item.invoice_no_)">{{ item.invoice_no_ }}</a>
      </template>
      <template v-slot:[`item.due_date`]="{ item }">{{ appDateFormat(item.due_date) }}</template>
      <template v-slot:[`item.amount`]="{ item }">{{ numberFormat(item.amount, 2) }}</template>
      <template v-slot:[`item.remaining_amount`]="{ item }"><span style="color:red" v-if="item.remaining_amount>0">{{ numberFormat(item.remaining_amount, 2) }}</span></template>
    </v-data-table>

    <v-card-text v-if="hideTable == true">
      <div class="text-center py-6">
        <v-icon large color="primary">mdi-thumb-up</v-icon>
        <br />
        <br />
        <span>Great!!!, no invoice unpaid..</span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import LoadingComponent from '@/components/LoadingComponent.vue'

export default defineComponent({
  name: 'InvoiceUnpaidTable',
  components: { LoadingComponent },
  props: {
    campaign_no_: String,
  },
  data: () => ({
    title: 'Unpaid Invoices',
    showLoadingComponent: false,
    hideTable: false,
    loading: false,
    loadingText: 'Loading data...',
    invoices: [],
    tableHeaders: [
      { text: 'Invoice No.', value: 'invoice_no_' },
      { text: 'Due Date', value: 'due_date' },
      { text: 'Contract No.', value: 'contract_no_' },
      { text: 'PO No.', value: 'po_no_' },
      // { text: 'Amount Excl VAT', value: 'amount', align: 'end' },
      { text: 'Amount Incl. VAT', value: 'amount', align: 'end' },
      { text: 'Unpaid Amount', value: 'remaining_amount', align: 'end' },
    ],
    dialog: null,
  }),
  computed: {
    no_: function () {
      return this.campaign_no_
    },
  },
  methods: {
    async getData() {
      this.hideTable = false
      this.loading = true
      await this.$axios
        .get('/campaign/detail/invoicing-unpaid', {
          params: { no_: this.no_ },
          cacheConfig: true,
        })
        .then(res => {
          if (res.status) {
            res = res.data
            this.invoices = res.data.invoicing

            if (this.invoices.length == 0) {
              this.hideTable = true
            }
          }
          this.loading = false
        })
        .catch(error => {
          // eslint-disable-next-line no-empty
          if (error.response) {
          } else if (error.request) {
            // eslint-disable-next-line no-empty
          } else {
          }
          this.$awn.alert('Internal Server Error')
          this.loading = false
        })
    },

    async downloadPdf(ivNo) {
      let params = {
        no_: ivNo,
      }
      this.$store.state.showLoadingDialog = true
      await this.$axios
        .get('documents/invoice', {
          params: params,
          responseType: 'blob',
          cacheConfig: true,
        })
        .then(response => {
          this.$store.state.showLoadingDialog = false
          // console.log(response.data)

          if (response.data.type == 'application/json') {
            this.showAlert('warning', 'File Not Found!')
          } else if (response.data.type == 'application/octet-stream') {
            const file = new Blob([response.data], { type: 'application/pdf' })
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file)
            //Open the URL on new Window
            window.open(fileURL)
          } else {
            this.showAlert('warning', 'File Not Found!')
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.$store.state.showLoadingDialog = false
          // console.log(error)
        })
    },
  },
})
</script>