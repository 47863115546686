var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":"","elevation":"2"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.title)+" "),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-1",attrs:{"fab":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.getData()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-sync")])],1)]}}])},[_c('span',[_vm._v("Refresh")])])],1),(_vm.showLoadingComponent)?_c('loading-component'):_vm._e(),(_vm.hideTable == false)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","loading":_vm.loading,"loading-text":_vm.loadingText,"no-data-text":'Great!!!, no invoice unpaid..',"headers":_vm.tableHeaders,"items":_vm.invoices,"item-key":"name"},scopedSlots:_vm._u([{key:"item.invoice_no_",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.downloadPdf(item.invoice_no_)}}},[_vm._v(_vm._s(item.invoice_no_))])]}},{key:"item.due_date",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.appDateFormat(item.due_date)))]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.numberFormat(item.amount, 2)))]}},{key:"item.remaining_amount",fn:function(ref){
var item = ref.item;
return [(item.remaining_amount>0)?_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.numberFormat(item.remaining_amount, 2)))]):_vm._e()]}}],null,true)}):_vm._e(),(_vm.hideTable == true)?_c('v-card-text',[_c('div',{staticClass:"text-center py-6"},[_c('v-icon',{attrs:{"large":"","color":"primary"}},[_vm._v("mdi-thumb-up")]),_c('br'),_c('br'),_c('span',[_vm._v("Great!!!, no invoice unpaid..")])],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }