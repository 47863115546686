<template>
  <v-card tile :loading="loading">
    <v-toolbar elevation="0">
      <v-icon>mdi-attachment</v-icon>
      <v-toolbar-title class="pl-2">Attachments</v-toolbar-title>
    </v-toolbar>
    <v-list dense>
      <!-- <v-subheader>Attachments</v-subheader> -->
      <v-list-item-group v-model="selectedItem" color="primary" v-if="attachments.length == 0">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Not found!</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      <v-list-item-group v-model="selectedItem" color="primary" v-if="attachments.length >= 1">
        <v-list-item v-for="(item, i) in attachments" :key="i" two-line link :href="item.file_src">
          <v-list-item-icon>
            <v-icon>{{ icons.mdiPaperclip }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title style="color: rgb(176, 176, 176)">{{ item.file_caption }}</v-list-item-title>
            <v-list-item-subtitle style="color: rgb(176, 176, 176)">{{ item.file_description }}</v-list-item-subtitle>
            <!-- <v-list-item-subtitle><v-icon size="15">{{ icons.mdiCalendarClock }}</v-icon>{{ item.upload_date }}</v-list-item-subtitle> -->
          </v-list-item-content>
          <v-list-item-action>
            <v-btn x-small text>
              <v-icon>{{ icons.mdiDownload }}</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>
<script>
import { mdiDotsVertical, mdiDolly, mdiCalendarClock, mdiPaperclip, mdiDownload } from '@mdi/js'

export default {
  data: () => ({
    no_: null,
    campaign: {},
    attachments: [],
    selectedItem: null,
    icons: {
      mdiDotsVertical,
      mdiDolly,
      mdiCalendarClock,
      mdiPaperclip,
      mdiDownload,
    },
    loading: false,
  }),
  methods: {
    async getData(no_) {
      this.loading = true
      this.attachments = []
      this.no_ = no_
      await this.$axios
        .get('/campaign/attachments', {
          params: { no_: no_ },
          cacheConfig: true,
        })
        .then(res => {
          this.loading = false
          res = res.data
          if (res.status == 'success') {
            this.attachments = res.data.attachments
          }
        })
    },
  },
}
</script>