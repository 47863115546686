<template>
  <v-card>
    <v-toolbar elevation="0">
      <v-icon>mdi-account</v-icon>
      <v-toolbar-title class="pl-2">Contact Person</v-toolbar-title>
    </v-toolbar>

    <v-card-text v-if="loading" class="d-flex justify-center py-2">
          <v-progress-circular
      class="mx-auto"
      v-if="loading"
      size="24"
      indeterminate
      color="primary"
    ></v-progress-circular>
    </v-card-text>

    <v-list
      two-line
      v-if="!loading"
    >
      <template v-for="(item, i) in items">
        <v-divider
          :key="i"
          v-if="i > 0 && i < items.length"
          :inset="true"
        ></v-divider>

        <v-list-item
          :key="'li'+i"
          @click="$refs.refContactDetailDialog.getDetail(item.id)"
        >
          <v-list-item-avatar class="my-0 py-0">
            <v-img :src="item.picture"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-html="item.full_name"></v-list-item-title>
            <v-list-item-subtitle :title="item.job_title + ' in ' + item.company_name">{{ item.job_title + ' in ' + item.company_name }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
    <ContactDetailDialog ref="refContactDetailDialog"></ContactDetailDialog>
  </v-card>
</template>
<script>
import ContactDetailDialog from '@/components/contact/ContactDetailDialog.vue'
export default {
  components: {
    ContactDetailDialog,
  },

  data: () => ({
    loading: false,
    no_: null,
    items: [],
  }),

  methods: {
    async getData(no_) {
      this.items = []
      this.no_ = no_
      this.loading = true
      await this.$axios
        .get('campaign/contacts', {
          params: { no_: no_ },
          cacheConfig: true,
        })
        .then(res => {
          this.loading = false
          var resData = res.data
          if (resData.status == 'success') {
            this.items = resData.data.contacts
          }
        })
    },
  },
}
</script>