<template>
  <div>
    <v-card :loading="loading" outlined>
      <v-card-title class="align-start">
        <span class="font-weight-semibold cursor-pointer" @click.stop="showContent = !showContent">{{ title }}</span>
        <v-spacer></v-spacer>
        <span class="text-xs text--disabled cursor-pointer mx-1" @click="getData(campaignNo)" title="Refresh">
          <v-icon>mdi-refresh</v-icon>
        </span>
        <span class="text-xs text--disabled cursor-pointer mx-1" @click.stop="dialog = true" title="Info">
          <v-icon>mdi-help-circle</v-icon>
        </span>
        <span class="text-xs cursor-pointer mx-1" @click.stop="showContent = !showContent" title="showContent">
          <v-icon v-if="!showContent">mdi-chevron-down</v-icon>
          <v-icon v-if="showContent">mdi-chevron-up</v-icon>
        </span>
      </v-card-title>

      <v-card-text v-show="showContent" class="pa-0">
        <div class="d-flex flex-sm-row flex-column">
          <div class="flex-grow-1">
            <v-card-title>
              <span class="me-3">Your Orders</span>
              <v-spacer></v-spacer>
              <!-- <span class="text-xs text--disabled cursor-pointer" @click="getData(campaignNo)">Refresh</span> -->
            </v-card-title>

            <v-card-text>
              <v-list>
                <v-list-item v-for="(data, index) in order_cofirmations" :key="data.img" :class="`d-flex px-0 ${index > 0 ? 'mt-4' : ''}`">
                  <v-avatar size="44" :color="data.color" rounded class="me-3 elevation-1">
                    <v-icon dark color="white" size="30">{{ data.icon }}</v-icon>
                  </v-avatar>

                  <div class="d-flex align-center flex-grow-1 flex-wrap">
                    <div class="me-auto pe-2">
                      <h4 class="font-weight-semibold">{{ data.title }}</h4>
                      <v-progress-circular class="me-1 mb-2" v-if="loading" size="24" indeterminate color="primary"></v-progress-circular>
                      <h3 class="text-xl font-weight-semibold" v-if="loading == false">{{ numberFormat(data.value, 2) }}</h3>
                    </div>
                  </div>
                </v-list-item>
              </v-list>
            </v-card-text>
          </div>

          <v-divider class="my-sm-5 mx-5" :vertical="$vuetify.breakpoint.smAndUp"></v-divider>

          <div class="flex-grow-1">
            <v-card-title>
              <span class="me-3">Material Delivery</span>
              <v-spacer></v-spacer>
              <!-- <span class="text-xs text--disabled cursor-pointer" @click="getData(campaignNo)">View All</span> -->
            </v-card-title>

            <v-card-text>
              <v-list>
                <v-list-item v-for="(data, index) in delivery_material" :key="data.img" :class="`d-flex px-0 ${index > 0 ? 'mt-4' : ''}`">
                  <v-avatar size="44" :color="data.color" rounded class="me-3 elevation-1">
                    <v-icon dark color="white" size="30">{{ data.icon }}</v-icon>
                  </v-avatar>

                  <div class="d-flex align-center flex-grow-1 flex-wrap">
                    <div class="me-auto pe-2">
                      <h4 class="font-weight-semibold">{{ data.title }}</h4>
                      <v-progress-circular class="me-1 mb-2" v-if="loading" size="24" indeterminate color="primary"></v-progress-circular>
                      <h3 class="text-xl font-weight-semibold" v-if="loading == false">{{ data.value }}</h3>
                    </div>
                  </div>
                </v-list-item>
              </v-list>
            </v-card-text>
          </div>

          <v-divider class="my-sm-5 mx-5" :vertical="$vuetify.breakpoint.smAndUp"></v-divider>

          <div class="flex-grow-1">
            <v-card-title>
              <span class="me-3">Material Receive & Return</span>
              <v-spacer></v-spacer>
              <!-- <span class="text-xs text--disabled cursor-pointer" @click="getData(campaignNo)">View All</span> -->
            </v-card-title>

            <v-card-text>
              <v-list>
                <v-list-item v-for="(data, index) in receive_return_material" :key="data.img" :class="`d-flex px-0 ${index > 0 ? 'mt-4' : ''}`">
                  <v-avatar size="44" :color="data.color" rounded class="me-3 elevation-1">
                    <v-icon dark color="white" size="30">{{ data.icon }}</v-icon>
                  </v-avatar>

                  <div class="d-flex align-center flex-grow-1 flex-wrap">
                    <div class="me-auto pe-2">
                      <h4 class="font-weight-semibold">{{ data.title }}</h4>
                      <v-progress-circular class="me-1 mb-2" v-if="loading" size="24" indeterminate color="primary"></v-progress-circular>
                      <h3 class="text-xl font-weight-semibold" v-if="loading == false">{{ data.value }}</h3>
                    </div>
                  </div>
                </v-list-item>
              </v-list>
            </v-card-text>
          </div>
        </div>
      </v-card-text>

      <v-card-text v-show="showContent" v-if="paidLeft>0">
        <v-card flat outlined elevation="1">
          <v-card-title>
            <h4 class="font-weight-semibold">Invoice Remaining</h4>
          </v-card-title>
          <v-card-text>
            <span class="text-xl" style="color:rgb(255, 0, 0) !important;font-weight:600 !important">Rp. {{ numberFormat(paidLeft, 2) }}</span>
          </v-card-text>
        </v-card>
      </v-card-text>

      <v-card-text v-show="showContent">
        <v-row v-if="!loading">
          <v-col>
            <p>{{ campaignData.info }}</p>
            <div v-if="(typeof campaignData.reference !== 'undefined')">
              <h3>Reference No.:</h3>
              <ol>
                <li v-for="(r, i) in campaignData.reference" :key="i">{{ r }}</li>
              </ol>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" max-width="720" scrollable>
      <v-card>
        <v-card-title>
          Info
          <v-spacer></v-spacer>
          <span class="text-xs text--disabled cursor-pointer mx-1" @click.stop="dialog = false" title="Close">
            <v-icon>mdi-close</v-icon>
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="max-height: 500px;">
          <v-list subheader three-line>
            <v-subheader>Your Orders</v-subheader>
            <v-list-item v-for="item in order_cofirmations" :key="item.title">
              <v-list-item-content>
                <v-row>
                  <v-col cols="1">
                    <v-list-item-icon class="mx-auto mt-0">
                      <v-icon large>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                  </v-col>
                  <v-col cols="11">
                    <v-list-item-title class="font-weight-bold">{{ item.title }}</v-list-item-title>
                    <div v-html="item.info" class="text--secondary"></div>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list subheader three-line>
            <v-subheader>Material Delivery</v-subheader>
            <v-list-item v-for="item in delivery_material" :key="item.title">
              <v-list-item-content>
                <v-row>
                  <v-col cols="1">
                    <v-list-item-icon class="mx-auto mt-0">
                      <v-icon large>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                  </v-col>
                  <v-col cols="11">
                    <v-list-item-title class="font-weight-bold">{{ item.title }}</v-list-item-title>
                    <div v-html="item.info" class="text--secondary"></div>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list subheader three-line>
            <v-subheader>Material Receive & Return</v-subheader>
            <v-list-item v-for="item in receive_return_material" :key="item.title">
              <v-list-item-content>
                <v-row>
                  <v-col cols="1">
                    <v-list-item-icon class="mx-auto mt-0">
                      <v-icon large>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                  </v-col>
                  <v-col cols="11">
                    <v-list-item-title class="font-weight-bold">{{ item.title }}</v-list-item-title>
                    <div v-html="item.info" class="text--secondary"></div>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click.stop="dialog = false" text>Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    title: 'Order Summary',
    showContent: true,
    loading: false,
    dialog: false,
    campaignNo: '',
    campaignData: {},
    showSummary: false,
    paidLeft: 0,
    order_cofirmations: [
      {
        icon: 'mdi-shopping',
        color: 'primary',
        title: 'Order Confirmation',
        value: 0,
        info: '',
      },
      {
        icon: 'mdi-cash',
        color: 'warning',
        title: 'Invoiced',
        value: 0,
        info: '',
      },
      {
        icon: 'mdi-credit-card-settings-outline',
        color: 'success',
        title: 'Invoice Paid',
        value: 0,
        info: '',
      },
    ],
    delivery_material: [
      {
        icon: 'mdi-dolly',
        color: 'primary',
        title: 'Delivery Preparation',
        value: 0,
        info: '',
      },
      {
        icon: 'mdi-truck-fast',
        color: 'warning',
        title: 'On Delivery',
        value: 0,
        info: '',
      },
    ],
    receive_return_material: [
      {
        icon: 'mdi-truck-check',
        color: 'success',
        title: 'Received by Customer',
        value: 0,
        info: '',
      },
      {
        icon: 'mdi-truck-delivery',
        color: 'primary',
        title: 'Returned by Customer',
        value: 0,
        info: '',
      },
    ],
  }),

  methods: {
    async getData(no_) {
      if (no_ == null) return
      this.loading = true

      this.campaignNo = no_

      await this.$axios
        .get('campaign/summary/order', {
          params: { no_: no_ },
        })
        .then(res => {
          this.loading = false
          var resData = res.data
          if (resData.status == 'success') {
            this.campaignData = resData.data

            this.showSummary = resData.data.show

            this.order_cofirmations[0].value = resData.data.order_confirmation
            this.order_cofirmations[1].value = resData.data.invoiced
            this.order_cofirmations[2].value = resData.data.invoice_paid

            this.order_cofirmations[0].info = resData.data.order_confirmation_info
            this.order_cofirmations[1].info = resData.data.invoiced_info
            this.order_cofirmations[2].info = resData.data.invoice_paid_info

            this.paidLeft = resData.data.paid_left

            this.delivery_material[0].value = this.numberFormat(resData.data.delivery_preparation, 2) + ' %'
            this.delivery_material[1].value = this.numberFormat(resData.data.on_delivery, 2) + ' %'
            this.delivery_material[0].info = resData.data.delivery_preparation_info
            this.delivery_material[1].info = resData.data.on_delivery_info

            this.receive_return_material[0].value = this.numberFormat(resData.data.received_by_customer, 2) + ' %'
            this.receive_return_material[1].value = this.numberFormat(resData.data.returned_by_customer, 2) + ' %'
            this.receive_return_material[0].info = resData.data.received_by_customer_info
            this.receive_return_material[1].info = resData.data.returned_by_customer_info
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.loading = false
          // console.log(error)
        })
    },
  },
}
</script>